<template>
  <div class="page-list-content" style="padding: 40px 5% !important">
    <div class="page-list-table m-0 p-0">
      <div class="row cpx-4 cpy-2">
        <span
          class="col-sm-6 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"
        >
          カテゴリ管理
        </span>
        <div
          class="col-sm-6 col-md-6 col-lg-6 tex-left text-md-right text-sm-right"
        >
          <button v-on:click="createCategory()" class="button-create-page">
            新規登録
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <Tables :items="listCategoryData.data" :fields="fields" class="cpx-4">
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:category_name="{ item }">
          <td style="min-width: 200px; width: 50%" class="py-2">
            <div class="text-truncate-mess">
              {{ item.category_name }}
            </div>
          </td>
        </template>
        <template v-slot:category_id="{ item }">
          <td style="min-width: 200px; width: 20%" class="py-2">
            <div class="text-truncate-mess">
              {{ item.category_id }}
            </div>
          </td>
        </template>
        <template v-slot:id="{ index }">
          <td>
            {{ listCategoryData.current_page * limit - limit + index + 1 }}
          </td>
        </template>
        <template v-slot:updated_at="{ item }">
          <td>
            {{
              new Date(item.updated_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex" style="min-width: 200px">
            <CButton
              class="mx-1 btn-other bg-0076ba"
              square
              v-on:click="showConfirmContent(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.modal-showContent
            >
              確認
            </CButton>
            <CButton
              class="mx-1 text-nowrap btn-other"
              square
              variant="outline"
              size="sm"
              v-on:click="editCategory(item.id)"
            >
              編集
            </CButton>
            <CButton
              class="mx-1 text-nowrap btn-del"
              square
              v-on:click="showConfirm(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-page
            >
              削除
            </CButton>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listCategoryData.total"
      >
        <p class="font-weight-bold">
          {{ listCategoryData.total }}件中{{
            listCategoryData.data.length
          }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listCategoryData.total"
      >
        <CPagination
          v-if="listCategoryData.total"
          :activePage.sync="page"
          :pages="listCategoryData.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-page" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removeCategory(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-page')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal id="modal-showContent" hide-header hide-footer>
        <div class="d-flex justify-content-between" style="padding: 20px">
          <div class="col-sm-4 d-flex align-items-center">
            <span> コンテンツ: </span>
          </div>
          <div class="col-sm-8">
            <span v-for="(item, index) in dataContent" :key="index"
              >{{ item.text }},&ensp;
            </span>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";

export default {
  name: "ListCategory",
  components: {
    Tables,
  },
  data() {
    return {
      fields: tableFields.CATEGORY,
      listChecked: [],
      dataModal: "",
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      dataContent: [],
      limit: 50,
      page: 1,
      // shop_id: null,
      shop_id:
        localStorage.getItem(
          Constants.ADMIN_USER_INFO +
            (this.$route.params.shopId
              ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
              : "_" + Constants.DOMAIN)
        ) &&
        JSON.parse(
          localStorage.getItem(
            Constants.ADMIN_USER_INFO +
              (this.$route.params.shopId
                ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                : "_" + Constants.DOMAIN)
          )
        ).shop_id,
      listCategoryData: [],
    };
  },
  async created() {
    await this.getListCate(1);
  },
  computed: {
    ...mapGetters([
      "listCategory",
      "message",
      "success",
      "error",
      "categoryById",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.getListCate(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listCategory() {
      this.listCategoryData = this.listCategory;
      this.page = this.listCategoryData.current_page;
    },
    page() {
      this.getListCate(this.page);
    },
    categoryById() {
      if (this.categoryById.contents !== undefined) {
        this.dataContent = this.categoryById.contents.map((item) => ({
          value: item.id,
          text: item.title,
        }));
      }
    },
  },
  methods: {
    ...mapActions({ getListCategory: "getListCategory" }),
    ...mapActions({ deleteCategory: "deleteCategory" }),
    ...mapActions({ getCategoryById: "getCategoryById" }),
    async getListCate(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
        },
      };
      await this.getListCategory(formData);
    },
    createCategory() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Create Category"
          : "Create Category domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    async showConfirmContent(id) {
      this.dataContent = [];
      this.dataModal = id;
      const data = {
        id: id,
        shop_id: this.shop_id,
      };
      await this.getCategoryById(data);
    },
    async removeCategory(id) {
      const data = {
        id: id,
        shop_id: this.shop_id,
      };
      const dataReturn = await this.$store.dispatch("deleteCategory", data);
      if (dataReturn.success) {
        this.$nextTick(() => {
          this.$bvModal.hide("delete-modal-page");
          this.getListCate(this.page);
        });
      }
    },
    editCategory(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Edit Category"
          : "Edit Category domain",
        params: { id: id },
      });
    },
    changeLimitPage() {
      this.getListCate(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
  },
};
</script>
